<template>
  <div class="barcode-scan">
    <div class="scan-container">
      <input type="text" v-model="barcode" @keyup.enter="submitBarcode" placeholder="Barcode scannen" class="barcode-input" />
      <button @click="submitBarcode" class="button">Barcode eingeben</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      barcode: '' // Eingegebener Barcode
    };
  },
  methods: {
    // Übermittelt den Barcode an die übergeordnete Komponente
    submitBarcode() {
      if (this.barcode.trim() !== '') {
        this.$emit('barcode-detected', this.barcode);
        this.barcode = '';
      }
    }
  }
};
</script>

<style scoped>
.barcode-scan {
  text-align: center;
  margin-top: 20px;
}

.scan-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.barcode-input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  text-align: center;
  width: 80%;
  max-width: 400px;
  margin-bottom: 10px;
}

.button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
}

.button:hover {
  background-color: #0056b3;
}
</style>
