<template>
  <div class="excel-reader">
    <h1 class="header">Inventarverwaltung</h1>
    <img src="@/assets/hsnr.png" alt="Logo" class="logo" />
    <div class="upload-container">
      <label for="file-upload" class="button">Datei auswählen</label>
      <input id="file-upload" type="file" @change="handleFileUpload" class="file-input" />
    </div>
  </div>
</template>

<script>
import * as XLSX from 'xlsx'; // Bibliothek zum Verarbeiten von Excel-Dateien

export default {
  methods: {
    // Handhabt den Datei-Upload und verarbeitet die Excel-Datei
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (!file) {
        return;
      }

      const reader = new FileReader();

      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });

        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        console.log('Eingelesene Daten:', jsonData);
        this.$emit('update-inventory', jsonData);
      };

      reader.onerror = (error) => {
        console.error('Datei konnte nicht gelesen werden:', error);
      };

      reader.readAsArrayBuffer(file);
    }
  }
};
</script>

<style scoped>
.excel-reader {
  text-align: center;
  margin-top: 20px;
}

.header {
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

.upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
}

.button:hover {
  background-color: #0056b3;
}

.file-input {
  display: none; /* Versteckt das Datei-Eingabefeld */
}
.logo {
    max-width: 360px;
  }
</style>
