<template>
  <div id="app">
    <!-- Header der Anwendung mit Navigation -->
    <header class="app-header">
      <nav>
        <ul>
          <li><a href="#" @click="navigateTo('home')">Startseite</a></li>
          <li><a href="https://www.hs-niederrhein.de/elektrotechnik-informatik/personen/">Hilfe</a></li>
        </ul>
      </nav>
    </header>
    <main class="main-content">
      <!-- Komponente zum Hochladen und Verarbeiten von Excel-Dateien -->
      <ExcelReader @update-inventory="updateInventory" />
      <!-- Komponente zum Scannen von Barcodes -->
      <BarcodeScan @barcode-detected="handleBarcode" />
      <!-- Anzeige von Erfolgs- und Fehlermeldungen -->
      <div v-if="feedbackMessage" class="feedback">{{ feedbackMessage }}</div>
      <div v-if="errorMessage" class="error">{{ errorMessage }}</div>
      <!-- Tabelle zur Anzeige der Inventardaten und des Scan-Status -->
      <table class="inventory-table">
        <!-- Tabellenkopf -->
        <tr>
          <th v-for="(header, index) in headers" :key="index">{{ header }}</th>
          <th>Scan-Status</th>
        </tr>
        <!-- Tabellendaten -->
        <tr v-for="(row, rowIndex) in inventory" :key="rowIndex">
          <td v-for="(cell, cellIndex) in row" :key="cellIndex">{{ cell }}</td>
          <td>{{ scanStatus[rowIndex] }}</td>
        </tr>
      </table>
      <!-- Button zum Hochladen der Datei auf den Server -->
      <button @click="downloadExcel" class="button upload-button">Datei herunterladen</button>
      </main>
    <footer class="app-footer">
      © 2024 Projekt.
    </footer>
  </div>
</template>

<script>
// Import der benötigten Komponenten und Bibliotheken
import ExcelReader from './components/ExcelReader.vue';
import BarcodeScan from './components/BarcodeScan.vue';
import jsPDF from 'jspdf';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import axios from 'axios'; // Bibliothek zum Hochladen von Dateien

export default {
  components: {
    ExcelReader,
    BarcodeScan
  },
  data() {
    return {
      headers: [], // Array zur Speicherung der Header der Excel-Tabelle
      inventory: [], // Array zur Speicherung der Inventardaten
      scanStatus: [], // Array zur Speicherung des Scan-Status für jedes Element
      feedbackMessage: '', // String zur Anzeige von Erfolgsnachrichten
      errorMessage: '' // String zur Anzeige von Fehlermeldungen
    };
  },
  methods: {
    // Methode zur Aktualisierung des Inventars basierend auf der hochgeladenen Excel-Datei
    updateInventory(data) {
      // Speichern der Header der Excel-Tabelle
      this.headers = data[0].map(header => header.toString().trim());
      // Speichern der Inventardaten, Entfernen von Leerzeichen
      this.inventory = data.slice(1).map(row => row.map(cell => cell.toString().trim()));
      // Initialisieren des Scan-Status-Arrays mit leeren Strings
      this.scanStatus = new Array(this.inventory.length).fill('');
      // Setzen der Erfolgsnachricht
      this.feedbackMessage = 'Inventarliste erfolgreich aktualisiert.';
      this.errorMessage = '';
    },
    // Methode zur Verarbeitung eines gescannten Barcodes
    handleBarcode(code) {
      code = code.trim(); // Entfernen von Leerzeichen
      // Finden des Index des Artikels im Inventar basierend auf dem Barcode
      const itemIndex = this.inventory.findIndex(item => item[0].trim() === code);
      if (itemIndex !== -1) {
        // Aktualisieren des Scan-Status für den gefundenen Artikel
        this.scanStatus[itemIndex] = 'Erfolgreich gescannt';
        // Setzen der Erfolgsnachricht
        this.feedbackMessage = `Artikel mit Anlagen-Nr. ${code} erfolgreich gescannt.`;
        this.errorMessage = '';
      } else {
        // Setzen der Fehlermeldung, wenn der Artikel nicht gefunden wurde
        this.errorMessage = `Artikel mit Anlagen-Nr. ${code} nicht gefunden.`;
        this.feedbackMessage = '';
      }
    },
    // Methode zum Hochladen der Excel-Datei auf den Server
    downloadExcel() {
      // Kombinieren der Inventardaten und des Scan-Status
      const dataToExport = this.inventory.map((row, index) => [...row, this.scanStatus[index]]);
      // Erstellen eines Arbeitsblatts aus den Daten
      const ws = XLSX.utils.aoa_to_sheet([['Anlagen-Nr.', ...this.headers, 'Scan-Status'], ...dataToExport]);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Inventar");
      // Schreiben der Arbeitsmappe in eine binäre Datei
      const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

      // Erstellen einer Blob-Datei und Herunterladen
      const blob = new Blob([wbout], { type: 'application/octet-stream' });
      saveAs(blob, 'inventar.xlsx');

      // Setzen der Erfolgsnachricht
      this.feedbackMessage = 'Datei erfolgreich heruntergeladen.';
      this.errorMessage = '';
    },
    // Methode zur Navigation zu verschiedenen Seiten (aktuell nur Startseite)
    navigateTo(page) {
      if (page === 'home') {
        window.history.pushState(null, null, '#home');
      }
    }
  }
};
</script>


<style scoped>
#app {
  font-family: 'Arial', sans-serif;
  text-align: center;
  color: #2c3e50;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
}

.app-header {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.app-header nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.app-header nav ul li {
  margin-right: 20px;
}

.app-header nav ul li:last-child {
  margin-right: 0;
}

.app-header nav ul li a {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
}

.main-content {
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.feedback, .error {
  margin-top: 20px;
  font-weight: bold;
}

.feedback {
  color: green;
}

.error {
  color: red;
}

.inventory-table {
  margin-top: 20px;
  width: 100%;
  max-width: 800px;
  border-collapse: collapse;
  text-align: left;
}

.inventory-table th, .inventory-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.inventory-table th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.upload-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
}

.app-footer {
  background-color: #f8f9fa;
  padding: 10px 20px;
  text-align: center;
  width: 100%;
}
</style>
